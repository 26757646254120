import React, {
    useEffect, useState, useContext,
} from 'react';
import { Box } from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import wbsQueries from '../../../../queries/wbs';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import Loader from '../../../../components/Loader';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import TotalValueType from '../../../../types/TotalValueType';
import { useOutletContext } from 'react-router-dom';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import WbsCustom from './WbsCustom';
import { truthyCodeValidation } from '../../../../helper/validations';
import { WBSDataTypeExtended } from '../../../../types/WbsType';
import { CellRendererType, ColumnDefs } from '../../../../types/AgGridTypes';
import { useDisplayCurrFormatter } from '../../../../hooks/useFormatCurrency';
// import SearchIcon from '@mui/icons-material/Search';

interface Props {
    getDefTotalValue: (totalValue: TotalValueType) => void,
    view?: string,
    searchText: string
    setSearchText?: React.Dispatch<React.SetStateAction<string>>;
    hideUnusedFlag: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;

}

export default function WBSDefaultView(props: Props) {
    const {
        view, getDefTotalValue, searchText, setSearchText, hideUnusedFlag, setIsFetching,
    } = props;
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const cont: OutletContext = useOutletContext();
    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefs[]>([]);

    const formatCurrency = useDisplayCurrFormatter();
    const projectCtx = useContext(ProjectContext);
    const userCtx = useContext(UserContext);
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const createData = (params: TotalValueType) => [
        {
            description: 'TOTAL',
            labour_hours: params?.labour_hoursTotal === null ? '-' : (+params.labour_hoursTotal.toFixed(0)),
            labour: params?.labourTotal,
            distributables: params?.distributableTotal,
            cons_equipment: params?.c_equipmentTotal,
            material: params?.materialTotal,
            // sustaining_cost: params?.sustaining_total,
            equipment: params?.equipmentTotal,
            subcontract: params?.subcontractTotal,
            total_cost: params?.total_costTotal,
        },
    ];

    const projectId = projectCtx?.project?.id || '';

    const { data, isFetching, refetch } = useGQLQuery(
        `GetWbsInfo-${projectId || 0}`,
        wbsQueries.GET_WBS_INFO(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            hideUnusedFlag,
        ),
        {},
        { onError: handleApiError },
    );

    // For WBS Default view...
    const wbsData = data?.wbs;
    const tableData = wbsData?.data;
    const pageInfo = wbsData?.pageInfo;
    const totalValue = wbsData?.total_values;
    // For Bottom Total Value..
    let pinnedBottomRowData;
    if (totalValue) {
        pinnedBottomRowData = createData(totalValue);
        // getDefTotalValue(totalValue);
    }

    useEffect(() => {
        if (totalValue) getDefTotalValue(totalValue);
    }, [totalValue]);

    useEffect(() => {
        refetch();
    }, [pageSkipValue, hideUnusedFlag]);

    const columnDefs = [
        {
            field: 'code',
            headerName: 'WBS',
            initialWidth: 130,
            cellRenderer: WbsCustom,
            type: 'string',
            sort: 'asc',
        },
        {
            field: 'description',
            headerName: 'Description',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 400,
            type: 'string',
        },
        {
            field: 'labour_hours',
            headerName: 'Labour hours',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
        },
        {
            field: 'labour',
            headerName: 'Labour',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 190,
            type: 'numericColumn',
        },
        {
            field: 'cons_equipment',
            headerName: 'C.Equipment',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
        },
        {
            field: 'material',
            headerName: 'Material',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 190,
            type: 'numericColumn',
        },
        {
            field: 'subcontract',
            headerName: 'Subcontract',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
        },
        {
            field: 'equipment',
            headerName: 'Equipment',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 190,
            type: 'numericColumn',
        },
        {
            field: 'distributables',
            headerName: 'Distributables',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
            enableValue: true,
        },
        {
            field: 'total_cost',
            headerName: 'Total Cost',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
            suppressMenu: false,
            sortable: true,
            rowGroup: false,
            enableValue: true,
        },
    ];
    // const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchText(e.target.value);
    // };

    const generateColumnDefs = () => {
        ['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].forEach((code, index) => {
            const flag = (tableData?.map((item) => ((item as WBSDataTypeExtended)[code])))?.some(truthyCodeValidation);
            if (flag) {
                columnDefs.push({
                    field: code,
                    headerName: `Code${index + 1}`,
                    initialWidth: 130,
                    cellRenderer: WbsCustom,
                    type: 'string',
                    initialHide: flag,
                    enableValue: false,
                });
            }
        });
        if (!isFetching && wbsData?.data) {
            wbsData?.data.forEach((d, index) => {
                if (d.labels && typeof d.labels === 'string') {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-param-reassign
                    d.labels = JSON.parse(d.labels);
                    if (typeof d.labels === 'object' && d.labels !== null) {
                        let labels = {};
                        labels = d.labels;
                        wbsData.data[index] = { ...d, ...labels };
                    }
                }
            });

            if (wbsData?.data?.length > 0 && wbsData?.data[0]?.labels) {
                Object.entries(wbsData?.data[0].labels).forEach(([key]) => {
                    columnDefs.push(
                        {
                            field: key,
                            headerName: `${key}`,
                            cellRenderer: (params: CellRendererType) => {
                                const { value } = params;
                                if (value === undefined) return '';
                                if (value === null) return '-';
                                return !Number.isNaN(Number(value)) ? formatCurrency((Number(value) || 0).toFixed(2)) : value;
                            },
                            initialWidth: 150,
                            type: 'string',
                            initialHide: true,
                            suppressMenu: false,
                            sortable: true,
                            rowGroup: false,
                            enableValue: true,
                        },
                    );
                });
            }
        }
        setNewColumnDefs(columnDefs);
    };

    useEffect(() => {
        generateColumnDefs();
        setIsFetching(isFetching);
    }, [isFetching]);

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };
    const rowData = tableData ? tableData.map((item) => ({
        ...item,
    })) : [];
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    return (
        <div>
            {/* Page Header Start */}
            <Loader loading={isFetching} />
            <Box>
                <AgGridComponent
                    columnDefs={newColumnDefs}
                    rowData={rowData}
                    changeSortingValue={() => { }}
                    pinnedBottomRowData={pinnedBottomRowData}
                    WbsRowBgStyle
                    isClipboardEnabled
                    isExportEnabled
                    isRangeSelectable
                    isToolPanelsEnabled
                    isPinnable
                    isStatusBarEnabled
                    isGroupable
                    moduleName="wbs_default"
                    colFormat={['labour', 'cons_equipment', 'material', 'subcontract', 'equipment', 'distributables', 'total_cost']}
                    footerFormat={['labour', 'cons_equipment', 'material', 'subcontract', 'equipment', 'distributables', 'total_cost']}
                    disableResizable
                    quickFilterText={searchText}
                    setSearchText={setSearchText}
                />
            </Box>
        </div>
    );
}
