/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-param-reassign */

import React, {
    useRef, useContext, LegacyRef,
    useEffect,
    useMemo,
    SetStateAction,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import Paper from '@mui/material/Paper';
import { Box, styled } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AgGridTableStyle from './AgGridTable.style';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    ColumnDefs, RowData, CellRendererType, IDetails,
} from '../../types/AgGridTypes';
import {
    ColDef, ColumnMenuTab, GridOptions, RowClassParams, MenuItemDef, ColumnState, RowGroupingDisplayType, ApplyColumnStateParams,
    IServerSideDatasource,
    FilterChangedEvent,
    SortChangedEvent,
    IsServerSideGroupOpenByDefaultParams,
    CheckboxSelectionCallbackParams,
    GridReadyEvent,
    RowStyle,
    RowGroupOpenedEvent,
    ColumnRowGroupChangedEvent,
    FilterOpenedEvent,
    GetMainMenuItemsParams,
} from 'ag-grid-community';
import OrganisationDetailType from '../../types/OrganisationDetailType';
import GetOrgUser from '../../types/GetOrgUser';
import ProjectListType from '../../types/ProjectListType';
import sortUpIcon from '../../assets/sortUpIcon.svg';
import sortDownIcon from '../../assets/sortDownIcon.svg';
import Loader from '../Loader';
import { UserContext } from '../../store/context/userContext';
import { JSONParse } from '../../helper/StorageHelper';
import { ReportType } from '../../types/Reports';
import { ProjectContext } from '../../store/context/projectContext';
import { useLocation } from 'react-router-dom';
import gridLayoutQueries from '../../queries/gridLayout';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import { useGQLMutation } from '../../hooks/useGQLMutation';
import IndustriesType from '../../types/IndustriesType';
import CommoditiesListType from '../../types/CommoditiesListType';
import getProjectLevel from '../../helper/ProjectLevel';
import formatRowData from '../../helper/rowFormat';
import CheckboxHeader from '../../screens/OrganisationSettings/components/ProjectAssignmentModal/CheckboxHeader';
import RowType from '../../types/GetProjectAssignedType';
import GridLayoutType from '../../types/GridLayout';
import { UnitRateContext } from '../../store/context/userRateIdLevelsData';

interface DynamicType {
    [key: string]: string | number,
}

interface CurrentGridLayout {
    id: string;
    org_id: string;
    user_id: string;
    project_id: string;
    grid_layout_id: string;
    created_at: string;
    updated_at: string;
}

interface EstimateGridLayoutType {
    id?: string;
    grid_state?: string;
    pivot_mode?: boolean;
    layout_name?: string;
    org_id: string;
    created_at: string;
    updated_at: string;
    current_grid_layouts?: CurrentGridLayout[];
}

  interface GetEstimateGridLayoutType {
    getEstimateGridLayout?: {
      data?: EstimateGridLayoutType[];
    }
  }

interface LocationState {
    pathname: string;
}

const allMenuTabs: ColumnMenuTab[] = ['generalMenuTab', 'filterMenuTab'];

interface Column {
    colId: string;
    width: number;
    hide: boolean;
    pinned: string | null;
    sort: string | null;
    sortIndex: number | null;
    aggFunc: string | null;
    rowGroup: boolean;
    rowGroupIndex: number | null;
    pivot: boolean;
    pivotIndex: number | null;
    flex: number;
  }
interface GridState {
    state: ColumnState[],
    filter: { [key: string]: any },
    getGridLayout?: {
        data?:{
        grid_state?: Column
        }
    }
}

interface GroupColDef {
    cellRendererParams: {
        suppressCount: boolean,
    }
}

interface StateType {
    state?: ColumnState[];
    filter?: Record<string, any>;
  }

function CustomLoadingCellRenderer() {
    return null;
}

interface AgGridComponentProps {
    unCheckFirstRow?: boolean;
    columnDefs: ColumnDefs[]; // Define your column definitions
    rowData?: RowData[]; // Define your row data
    sortOrder?: string;
    changeSortingValue: (par: string) => void;
    gridRef?: React.RefObject<AgGridReact<OrganisationDetailType>> | (React.RefObject<AgGridReact<RowType>>)
    | (React.RefObject<AgGridReact<GetOrgUser>>) |(React.RefObject<AgGridReact<ProjectListType>>)
    | React.RefObject<AgGridReact<ReportType>> | (React.RefObject<AgGridReact<IndustriesType>>)
    | (React.RefObject<AgGridReact<CommoditiesListType>>);
    onSelectionChanged?: (res: any) => void;
    masterDetail?: boolean;
    detailCellRendererParams?: IDetails
    noSelection?: boolean;
    allSelection?: boolean;
    rowHeight?: number;
    headerHeight?: number;
    groupIncludeTotalFooter?: boolean;
    pinnedBottomRowData?: any[];
    noSorting?: boolean,
    isPinnable?: boolean,
    isGroupable?: boolean,
    isRangeSelectable?: boolean,
    isRangeHandle?: boolean,
    isExportEnabled?: boolean,
    isClipboardEnabled?: boolean,
    isToolPanelsEnabled?: boolean,
    isStatusBarEnabled?: boolean,
    isColumnMovable?: boolean,
    isLoading?: boolean,
    WbsRowBgStyle?: boolean,
    IndCommoRowBGStyle?: boolean,
    unitRateRowBGStyle?: boolean,
    IndCommoLevel?: number,
    CoaRowBgStyle?: boolean,
    isCommodities?: boolean
    quickFilterText?: string,
    moduleName?: string,
    defaultExpanded?: number,
    customGroupRowRenderer?: any,
    disableResizable?: boolean,
    colFormat?: string[],
    masterDataColFormat?: string[],
    footerFormat?: string[],
    groupHideOpenParents?: boolean,
    groupDisplayType?: RowGroupingDisplayType,
    isAutoGroupColumnDefSuppressCount?: boolean,
    rowSelection?: 'single' | 'multiple',
    setSearchText?: React.Dispatch<React.SetStateAction<string>>
    handleReset?: () => void,
    reportsParentRow?:string | undefined,
    datasource?: IServerSideDatasource,
    onFilterChanges?: (event: FilterChangedEvent) => void,
    onSortChanges?: (event: SortChangedEvent) => void,
    onRowGroupOpened?: (event: RowGroupOpenedEvent) => void,
    onColumnRowGroupChanges?: (event: ColumnRowGroupChangedEvent) => void,
    onFilterOpens?: (event: FilterOpenedEvent) => void,
    groupsOpenByDefault?: (params: IsServerSideGroupOpenByDefaultParams) => boolean,
    loadingRenderer?: boolean,
    suppressScrollOnNewData?:boolean,
    isAssociation?: boolean,
    isMultiFilterRequired?: boolean,
    suppressAggFuncInHeader?: boolean,
    // noRowSelection?: boolean,
    projectAssignmentPopupCustomSelection?: boolean,
    setGridLayoutData?: React.Dispatch<SetStateAction<EstimateGridLayoutType | any>>
    handleOpen?: (p: boolean) => void,
    setGridState?: React.Dispatch<React.SetStateAction<GridState | any>>;
    saveEstimateGridLoading?: boolean,
    gridView?: string,
    isEstimateResetLoading?: boolean,
    gridState?: StateType,
    setLayoutName?:(p:string)=>void,
    estimateGridLayoutState?: GetEstimateGridLayoutType,
    estimateRefetch?:() => void,
    isFetchingEstimate?: boolean,
    autoGroupColumnDefs?: ColDef,
    setCanRenderClientSide?: React.Dispatch<React.SetStateAction<boolean>>,
    isEditGridLayouttLoading?: boolean,
}

const Main = styled('main')({
    flexGrow: 1,
    // height: '80vh',
    overflow: 'auto',
});

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    margin: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
}));

function AgGridComponent({
    columnDefs,
    rowData,
    sortOrder,
    changeSortingValue,
    masterDetail,
    detailCellRendererParams,
    onSelectionChanged = () => { },
    gridRef,
    noSelection,
    allSelection,
    rowHeight,
    headerHeight,
    pinnedBottomRowData,
    groupIncludeTotalFooter,
    noSorting,
    isLoading,
    isPinnable,
    isGroupable,
    isRangeSelectable,
    isRangeHandle,
    isExportEnabled,
    isClipboardEnabled,
    isToolPanelsEnabled,
    isStatusBarEnabled,
    isColumnMovable,
    WbsRowBgStyle,
    CoaRowBgStyle,
    IndCommoRowBGStyle,
    unitRateRowBGStyle,
    IndCommoLevel,
    moduleName,
    isCommodities,
    defaultExpanded,
    customGroupRowRenderer,
    quickFilterText,
    disableResizable,
    colFormat,
    masterDataColFormat,
    footerFormat,
    groupHideOpenParents,
    groupDisplayType,
    rowSelection,
    setSearchText,
    handleReset,
    isAutoGroupColumnDefSuppressCount,
    reportsParentRow,
    datasource,
    onFilterChanges,
    onSortChanges,
    groupsOpenByDefault,
    loadingRenderer,
    suppressScrollOnNewData,
    unCheckFirstRow,
    isAssociation,
    onFilterOpens,
    isMultiFilterRequired = true,
    suppressAggFuncInHeader,
    onRowGroupOpened,
    onColumnRowGroupChanges,
    // noRowSelection,
    projectAssignmentPopupCustomSelection,
    setGridLayoutData,
    handleOpen,
    setGridState,
    saveEstimateGridLoading,
    gridView,
    isEstimateResetLoading,
    autoGroupColumnDefs,
    gridState,
    setLayoutName,
    estimateGridLayoutState,
    estimateRefetch,
    isFetchingEstimate,
    setCanRenderClientSide,
    isEditGridLayouttLoading,
}: AgGridComponentProps) {
    const userCtx = useContext(UserContext);
    const UnitRateIdRowDataCtx = useContext(UnitRateContext);
    const classes = AgGridTableStyle();
    const gridRefCommon = useRef<AgGridReact<OrganisationDetailType> | AgGridReact<GetOrgUser> | AgGridReact<IndustriesType>
    | AgGridReact<ProjectListType> | AgGridReact<CommoditiesListType>>(null);
    const activeGridRef = gridRef || gridRefCommon;
    const activeModuleName = moduleName ? `${moduleName}_${userCtx?.user?.user_id || ''}` : '';
    const projectCtx = useContext(ProjectContext);
    const curLocation:LocationState = useLocation();

    const gridViewRef = useRef(gridView);
    useEffect(() => {
        gridViewRef.current = gridView;
    }, [gridView]);

    if (!(moduleName === 'estimate' || moduleName === 'UnitRateListView')) {
        UnitRateIdRowDataCtx?.setUnitRateId('');
    }

    const customHeaderComponent = (param: CellRendererType) => (
        <div
            role="presentation"
            className="AgHeaderCustom"
            onClick={() => changeSortingValue(param?.column?.colDef?.field)}
        >
            <div className={classes.AgHeaderCustomDesc}>
                {param?.column?.colDef?.headerName}
                {sortOrder === 'DESC' ? (
                    <span>
                        <KeyboardArrowDownIcon />
                    </span>
                ) : (
                    <span>
                        <KeyboardArrowUpIcon />
                    </span>
                )}
            </div>
        </div>
    );

    const menuTabs = allMenuTabs.slice(isPinnable || isGroupable || activeModuleName ? 0 : 1);

    const defaultColDef: ColDef = useMemo(() => ({
        menuTabs,
        suppressMovable: !isColumnMovable,
        initialFlex: disableResizable ? 0 : 1,
        filter: true,
        enableRowGroup: true,
        enableValue: true,
        enablePivot: true,
        sortable: !noSorting,
        // unSortIcon: !noSorting,
        icons: {
            sortAscending: () => `<img src="${sortUpIcon}" width="10" height="10" alt="Asc" class="custom-icon" />`,
            sortDescending: () => `<img src="${sortDownIcon}" width="10" height="10" alt="Desc" class="custom-icon" />`,
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    columnDefs.forEach((columnDef) => {
        columnDef.minWidth = 50;
        // if (columnDef.sorting) {
        //     // eslint-disable-next-line no-param-reassign
        //     columnDef.headerComponentFramework = customHeaderComponent;
        // }
        columnDef.resizable = true;
        if (isMultiFilterRequired !== false) {
            switch (columnDef.type) {
            case 'string': columnDef.filter = 'agMultiColumnFilter';
                break;
            case 'numericColumn':
            case 'number': columnDef.filter = 'agMultiColumnFilter';
                columnDef.filterParams = {
                    filters: [
                        {
                            filter: 'agNumberColumnFilter',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        },
                    ],
                };
                break;
            default: columnDef.filter = 'agSetColumnFilter';
            }
        }
        if (isMultiFilterRequired === false && !columnDef.filter) {
            switch (columnDef.type) {
            case 'string': columnDef.filter = 'agTextColumnFilter';
                break;
            case 'numericColumn':
            case 'number': columnDef.filter = 'agNumberColumnFilter';
                break;
            default: columnDef.filter = 'agSetColumnFilter';
            }
        }
    });

    const { mutate, isLoading: saveGridLoading } = useGQLMutation(
        'SaveGridLayout',
        gridLayoutQueries.SAVE_GRID_LAYOUT,
        {},
        '/list',
    );

    const { data: newGridLayoutState, isFetching, refetch } = useGQLQuery(
        `GetGridLayout-${activeModuleName}`,
        gridLayoutQueries.GET_GRID_LAYOUT(
            moduleName || '',
        ),
        {},
        {},
    );

    const hanldleReset = () => {
        estimateRefetch?.();
        refetch();
    };

    const { mutate: resetMutate, isLoading: isResetLoading } = useGQLMutation(
        `resetGridLayout-${activeModuleName}`,
        gridLayoutQueries.RESET_GRID_LAYOUT,
        {
            onSuccess: hanldleReset,
        },
        '/list',
    );

    const { mutate: resetEstimateMutate, isLoading: isResetEstimateLoading } = useGQLMutation(
        'resetEstimateGridLayout',
        gridLayoutQueries.RESET_ESTIMATE_GRID_LAYOUT,
        {
            onSuccess: hanldleReset,
        },
        '/list',
    );

    // Reloading grid
    const resetGridState = (param?:GetMainMenuItemsParams) => {
        if (activeGridRef.current && activeGridRef) {
            if (suppressAggFuncInHeader) {
                // For dashboard page Industries and Region grid, the sort is reset, excluding agg function reset
                activeGridRef.current.columnApi?.applyColumnState({ defaultState: { sort: null } });
                activeGridRef.current.api?.setColumnDefs(columnDefs);
            } else {
                activeGridRef.current.columnApi?.resetColumnState();
                activeGridRef.current.api?.setFilterModel(null);
                handleReset?.();
                if (setSearchText) {
                    setSearchText('');
                }
            }
        }
    };

    // Clear filter
    const clearFilter = () => {
        if (activeGridRef && activeGridRef.current) {
            activeGridRef.current.api?.setFilterModel(null);
        }
    };
    const gridOptions: GridOptions = {
        enableRangeSelection: isRangeSelectable,
        enableRangeHandle: isRangeSelectable,
        groupAllowUnbalanced: true,
        embedFullWidthRows: true,
        onFilterChanged: onFilterChanges,
        onSortChanged: onSortChanges,
        onRowGroupOpened,
        onColumnRowGroupChanged: onColumnRowGroupChanges,
        onFilterOpened: onFilterOpens,
        loadingCellRenderer: loadingRenderer ? CustomLoadingCellRenderer : undefined,
        // copyHeadersToClipboard: true,
        getMainMenuItems: (param) => {
            const returnArray: (string | MenuItemDef)[] = [];
            if (isPinnable) returnArray.push('pinSubMenu');
            if (isGroupable) returnArray.push('rowGroup');
            if (isPinnable || isGroupable) returnArray.push('separator');
            returnArray.push('autoSizeThis');
            returnArray.push('autoSizeAll');
            if (!param.column.getColId().startsWith('attr__')) {
                returnArray.push('separator');
                returnArray.push({
                    name: 'Sort Ascending',
                    action: () => {
                        const columnState: ApplyColumnStateParams = {
                            state: [
                                {
                                    colId: param.column.getColId(),
                                    sort: 'asc',
                                },
                            ],
                        };
                        activeGridRef.current?.columnApi.applyColumnState(columnState);
                    },
                });
                returnArray.push({
                    name: 'Sort Descending',
                    action: () => {
                        const columnState: ApplyColumnStateParams = {
                            state: [
                                {
                                    colId: param.column.getColId(),
                                    sort: 'desc',
                                },
                            ],
                        };
                        activeGridRef.current?.columnApi.applyColumnState(columnState);
                    },
                });
                returnArray.push({
                    name: 'Reset Sort',
                    action: () => {
                        const columnState: ApplyColumnStateParams = {
                            state: [
                                {
                                    colId: param.column.getColId(),
                                    sort: null,
                                },
                            ],
                        };
                        activeGridRef.current?.columnApi.applyColumnState(columnState);
                    },
                });
            }
            if (activeModuleName) {
                returnArray.push('separator');
                if (moduleName === 'estimate') {
                    returnArray.push({
                        name: 'Save Grid Layout',
                        action: () => {
                            const stateTobeStored: GridState = {
                                state: param.columnApi.getColumnState(),
                                filter: param.api.getFilterModel(),
                            };
                            if (setGridState) {
                                setGridState(stateTobeStored);
                            }
                            // param.columnApi?.resetColumnState();
                            handleOpen?.(true);
                        },
                    });
                } else {
                    returnArray.push({
                        name: 'Save Grid Layout',
                        action: () => {
                            const stateTobeStored: GridState = {
                                state: param.columnApi.getColumnState(),
                                filter: param.api.getFilterModel(),
                            };
                            mutate({
                                module_name: moduleName,
                                pivotMode: param.columnApi.isPivotMode(),
                                grid_state: JSON.stringify(stateTobeStored),
                            });
                        },
                    });
                }
            }
            if (activeModuleName) {
                returnArray.push('separator');
                if (moduleName === 'estimate') {
                    returnArray.push({
                        name: 'Reset Columns',
                        action: () => {
                            resetGridState();
                            const stat = param.columnApi?.resetColumnState();
                            resetEstimateMutate({
                                org_id: userCtx?.user?.default_org_id || '',
                                project_id: projectCtx?.project?.id || '',
                                layout_name: gridViewRef?.current,
                                grid_state: JSON.stringify(stat) || '',
                                module_name: 'estimate',
                            });
                        },
                    });
                } else {
                    returnArray.push({
                        name: 'Reset Columns',
                        action: () => {
                            resetMutate({
                                module_name: moduleName,
                            });
                            resetGridState();
                        },
                    });
                }
                returnArray.push({
                    name: 'Clear Filters',
                    action: () => {
                        clearFilter();
                    },
                });
            }
            if (moduleName === 'estimate') {
                const colId = param.column.getColId();
                const userColDef = param.column.getUserProvidedColDef();
                returnArray.forEach((menuOption, i) => {
                    const autoGroupCol = colId === 'ag-Grid-AutoColumn';
                    const rowGroupCheck = menuOption === 'rowGroup';
                    const filtersCheck = typeof (menuOption) === 'object' && menuOption?.name === 'Clear Filters';
                    const sortCheck = typeof (menuOption) === 'object' && menuOption?.name === 'Sort Ascending';
                    if ((autoGroupCol || userColDef?.enableRowGroup === false) && rowGroupCheck) returnArray.splice(i, 1);
                    if ((autoGroupCol || userColDef?.sortable === false) && sortCheck) returnArray.splice(i, 4);
                    if ((autoGroupCol || userColDef?.suppressFiltersToolPanel === true) && filtersCheck) returnArray.splice(i, 1);
                    if (colId.startsWith('attr__') && typeof (menuOption) === 'object' && menuOption?.name === 'Save Grid Layout') {
                        returnArray.splice(i, 2);
                    }
                });
            }
            return returnArray;
        },
        getContextMenuItems: () => {
            const returnArray = [];
            if (isClipboardEnabled) returnArray.push('copy');
            if (isClipboardEnabled) returnArray.push('copyWithHeaders');
            if (isExportEnabled) returnArray.push('export');
            return returnArray;
        },
        rowHeight: rowHeight || 27,
        headerHeight,
        sideBar: isToolPanelsEnabled ? {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressPivots: true,
                    },
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250,
                },
            ],
        } : null,
        statusBar: isStatusBarEnabled ? {
            statusPanels: [
                {
                    statusPanel: 'agAggregationComponent',
                    align: 'right',
                },
            ],
        } : undefined,
        autoGroupColumnDef: isAutoGroupColumnDefSuppressCount
            ? {
                cellRendererParams: { suppressCount: true },
                maxWidth: 40,
                suppressMenu: true,
                sortable: false,
            }
            : {
                minWidth: 40,
                sortable: false,
                resizable: true,
            },
        suppressScrollOnNewData,
        suppressAggFuncInHeader,
        suppressRowClickSelection: moduleName === 'estimate' ? true : undefined,
    };
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = curLocation?.pathname === '/industries' || curLocation?.pathname === '/commodities' ? 1 : displayCurr?.exchange_rate;

    const updatedRowData = formatRowData(rowData, colFormat, exchangeRate, curLocation, masterDetail, masterDataColFormat);

    const tableData = colFormat ? updatedRowData : rowData;

    // Function to Multiply table Footer values with Exchange rate in Project related pages
    let newPinnedBottomRowData: object[] = [];
    if (footerFormat && footerFormat.length && exchangeRate && pinnedBottomRowData && pinnedBottomRowData.length) {
        newPinnedBottomRowData = pinnedBottomRowData?.map((obj) => {
            const newObj = {};
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            Object.keys(obj).forEach((key) => {
                if (footerFormat.includes(key)) {
                    (newObj as DynamicType)[key] = (+((obj as DynamicType)[key]) / exchangeRate).toFixed(0);
                } else {
                    (newObj as DynamicType)[key] = (obj as DynamicType)[key];
                }
            });
            return newObj;
        });
    }

    const footerData = footerFormat ? newPinnedBottomRowData : pinnedBottomRowData;
    // header component is registered by custom checkbox header passed using frameWork.
    const checkedCustomColumn: ColumnDefs = {
        field: 'blank',
        headerName: '',
        checkboxSelection: (params: CheckboxSelectionCallbackParams) => true,
        width: 40,
        maxWidth: 40,
        headerComponent: 'checkboxHeader',
        headerComponentParams: {
            checkboxSelection: true,
        },
        lockPosition: moduleName === 'estimate' ? 'left' : undefined,
    };

    // unCheckFirstRow is used to control the checkbox selection on first row for coaCode-description.
    const checkedColumn: ColumnDefs = {
        field: 'blank',
        headerName: '',
        checkboxSelection: (params: CheckboxSelectionCallbackParams) => (!(unCheckFirstRow && params.node.rowIndex === 0)),
        headerCheckboxSelection: allSelection !== undefined ? allSelection : true,
        width: 30,
        maxWidth: 30,
        enableValue: datasource ? false : undefined,
        enableRowGroup: datasource ? false : undefined,
        lockPosition: moduleName === 'estimate' ? 'left' : undefined,
    };

    const selectedProject = projectCtx?.project;

    const selectColor = (page: string) => {
        let projectLevel;
        const bgColorList = ['#88B0FF', '#AECAFF', '#C1D6FF', '#dbe6fc', 'transperant', 'transperant'];
        if (page === 'wbs') {
            projectLevel = getProjectLevel([
                selectedProject?.wbs1,
                selectedProject?.wbs2,
                selectedProject?.wbs3,
                selectedProject?.wbs4,
                selectedProject?.wbs5,
                selectedProject?.wbs6,
            ]);
        }
        if (page === 'coa') {
            projectLevel = getProjectLevel([
                selectedProject?.coa1,
                selectedProject?.coa2,
                selectedProject?.coa3,
                selectedProject?.coa4,
                selectedProject?.coa5,
                selectedProject?.coa6,
            ]);
        }
        if (page === 'IndCommo') projectLevel = IndCommoLevel;
        switch (projectLevel) {
        case 1:
            return bgColorList.slice(5);
        case 2:
            return bgColorList.slice(4);
        case 3:
            return bgColorList.slice(3);
        case 4:
            return bgColorList.slice(2);
        case 5:
            return bgColorList.slice(1);
        default:
            return bgColorList;
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // if NoSelection then checkbox is not added ,if projectAssignmentPopupCustomSelection then custom checkBox is added or normal checkbox get addeed
    const columnDefWithDefault = useMemo(() => {
        if (noSelection) return columnDefs;
        return projectAssignmentPopupCustomSelection
            ? [checkedCustomColumn, ...columnDefs]
            : [checkedColumn, ...columnDefs];
    }, [columnDefs]);

    const handleEstimateStyles = (params: RowClassParams): RowStyle => {
        // console.log(params?.node);
        if (params.columnApi.isPivotMode()) {
            return { 'background-color': 'transperant' };
        }
        const { node } = params;
        const casedata = projectCtx?.projectCaseData;
        const wbsLevel = getProjectLevel([
            selectedProject?.wbs1,
            selectedProject?.wbs2,
            selectedProject?.wbs3,
            selectedProject?.wbs4,
            selectedProject?.wbs5,
            selectedProject?.wbs6,
        ]);
        const selectBgColor = () => {
            const bgColorList = ['#88B0FF', '#AECAFF', '#C1D6FF', '#dbe6fc', '#eff4ff', 'transperant'];
            let updatedColorList = [];
            if (casedata && casedata.length > 1) {
                if (wbsLevel === 1) {
                    updatedColorList = bgColorList.slice(3);
                } else if (wbsLevel === 2) {
                    updatedColorList = bgColorList.slice(2);
                } else if (wbsLevel === 3) {
                    updatedColorList = bgColorList.slice(1);
                } else {
                    updatedColorList = bgColorList;
                }
            } else if (wbsLevel === 1) {
                updatedColorList = bgColorList.slice(4);
            } else if (wbsLevel === 2) {
                updatedColorList = bgColorList.slice(3);
            } else if (wbsLevel === 3) {
                updatedColorList = bgColorList.slice(2);
            } else {
                updatedColorList = bgColorList.slice(1);
            }
            updatedColorList.reverse();
            let color;
            const [transparent, firstColor, secondColor, thirdColor] = updatedColorList;
            switch (node?.field) {
            case 'case.name':
                if (casedata && casedata.length > 1) {
                    color = updatedColorList.at(-1);
                } else {
                    color = 'transparent';
                }
                break;
            case 'wbs.first_parent':
                if (casedata && casedata.length > 1) {
                    color = updatedColorList.at(-2);
                } else {
                    color = updatedColorList.at(-1);
                }
                break;
            case 'wbs.second_parent':
                if (wbsLevel === 3) color = secondColor;
                if (wbsLevel > 3) color = thirdColor;
                if (wbsLevel < 3) color = 'transparent';
                break;
            case 'wbs.third_parent':
                if (wbsLevel > 3) color = secondColor;
                else color = 'transparent';
                break;
            case 'wbs.self_code_desc':
                color = firstColor;
                break;
            case 'coa.first_parent':
                color = `${updatedColorList[0]} !important`;
                break;
            default:
                color = '#F8F8F8';
                break;
            }
            return color;
        };

        const bgColorArr = selectBgColor() || 'transperant';
        return { 'background-color': bgColorArr };
    };

    // To apply Background color in WBS and COA page based on level
    const getRowStyle = (params: RowClassParams): RowStyle => {
        if (params?.data && params?.node?.key && autoGroupColumnDefs) {
            return handleEstimateStyles(params);
        }
        if (WbsRowBgStyle) {
            const bgColorArr = selectColor('wbs');
            if (params?.data?.level === 1) return { 'background-color': bgColorArr[0] };
            if (params?.data?.level === 2) return { 'background-color': bgColorArr[1] };
            if (params?.data?.level === 3) return { 'background-color': bgColorArr[2] };
            if (params?.data?.level === 4) return { 'background-color': bgColorArr[3] };
            if (params?.data?.level === 5) return { 'background-color': bgColorArr[4] };
            if (params?.data?.level === 6) return { 'background-color': bgColorArr[5] };
        }
        if (CoaRowBgStyle) {
            const bgColorArr = selectColor('coa');
            if (params?.data?.level === 1) return { 'background-color': bgColorArr[0] };
            if (params?.data?.level === 2) return { 'background-color': bgColorArr[1] };
            if (params?.data?.level === 3) return { 'background-color': bgColorArr[2] };
            if (params?.data?.level === 4) return { 'background-color': bgColorArr[3] };
            if (params?.data?.level === 5) return { 'background-color': bgColorArr[4] };
            if (params?.data?.level === 6) return { 'background-color': bgColorArr[5] };
        }
        if (IndCommoRowBGStyle) {
            const bgColorArr = selectColor('IndCommo');
            if (params?.data?.level === 1) return { 'background-color': bgColorArr[0] };
            if (params?.data?.level === 2) return { 'background-color': bgColorArr[1] || 'transperant' };
            if (params?.data?.level === 3) return { 'background-color': bgColorArr[2] || 'transperant' };
            if (params?.data?.level === 4) return { 'background-color': bgColorArr[3] || 'transperant' };
            if (params?.data?.level === 5) return { 'background-color': bgColorArr[4] || 'transperant' };
            if (params?.data?.level === 6) return { 'background-color': bgColorArr[5] || 'transperant' };
        }
        if (reportsParentRow) {
            if (params?.data?.id === reportsParentRow) return { 'background-color': '#C6FFCA' };
        }
        if (unitRateRowBGStyle) {
            if (params?.data?.shouldStyleApply) return { 'font-weight': 'bold' };
        }
        return { 'background-color': 'transperant' };
    };

    const groupDisplayTypeVal = groupDisplayType || 'groupRows';

    const groupDefaultExpanded = defaultExpanded || 0;

    // Function to autosize cloumns w.r.t cell content
    // We are not applying the autosize for below grids
    const pageList = ['commodities', 'OrgList', 'Industrie', 'Industry', 'Region', 'projectDashboardCasesAndPhases',
        'projectDashboardWbs', 'projectDashboardCoa', 'projectDashboardCurrency', 'OrgSettings', 'ImportLog', 'ProjectsMapView'];
    const applyAutosize = () => {
        if (activeGridRef && activeGridRef.current && activeGridRef.current?.columnApi
            && (!pageList.includes(moduleName || '')) && curLocation?.pathname !== '/reports' && curLocation?.pathname !== '/projectReports') {
            activeGridRef.current?.columnApi.autoSizeAllColumns();
        }
    };

    const filteredData = estimateGridLayoutState
     // eslint-disable-next-line no-mixed-operators
     && estimateGridLayoutState?.getEstimateGridLayout?.data?.filter((item: any) => item?.layout_name === gridView) || [];

    const applyGridState = () => {
        // Commenting this block, since layout is applied to grid persistance also
        // if (UnitRateIdRowDataCtx.unitRateId) {

        const canRenderClientSide = UnitRateIdRowDataCtx?.unitRateId;
        // }
        // const gridStateObj = JSONParse<GridState>(newGridLayoutState?.getGridLayout?.data?.grid_state as string | undefined);
        if (moduleName === 'estimate' && filteredData) {
            const tem = estimateGridLayoutState && filteredData && filteredData[0];
            const gridStateObj = JSONParse<GridState>(tem?.grid_state);
            if (gridStateObj?.state) {
                const valueColsMap = new Map(UnitRateIdRowDataCtx?.valueColsData.map((col) => [col.id, col]));
                activeGridRef.current?.columnApi.setPivotMode(tem?.pivot_mode as boolean);
                activeGridRef.current?.columnApi?.applyColumnState({
                    state: gridStateObj.state.map((column) => {
                        if (canRenderClientSide) {
                            // In client side we will not allow sort and rowGroup to apply from layout
                            const {
                                sort, sortIndex, rowGroup, rowGroupIndex, ...rest
                            } = column;

                            const valueCol = valueColsMap.get(column.colId);
                            return {
                                ...rest,
                                // sort: UnitRateIdRowDataCtx.sortModel.find((col) => col.colId === column.colId)?.sort || null,
                                // sortIndex: UnitRateIdRowDataCtx.sortModel.findIndex((col) => col.colId === column.colId) || null,
                                // rowGroup: !!rowGroupCol,
                                // This will make the column unhide, if aggFunction is present
                                hide: valueCol?.aggFunc ? false : column?.hide,
                                aggFunc: valueCol?.aggFunc || null,
                            };
                        }
                        return column;
                    }),
                    applyOrder: true,
                });
            }
            if (gridStateObj?.filter && !canRenderClientSide) {
                activeGridRef.current?.api?.setFilterModel(gridStateObj?.filter);
            }
        } else if (newGridLayoutState) {
            const gridStateObj = JSONParse<GridState>(newGridLayoutState?.getGridLayout?.data?.grid_state as string | undefined);
            if (gridStateObj?.state) {
                activeGridRef.current?.columnApi?.applyColumnState({
                    state: gridStateObj?.state,
                    applyOrder: true,
                });
            }
            if (gridStateObj?.filter) {
                activeGridRef.current?.api?.setFilterModel(gridStateObj?.filter);
            }
        }
    };

    useEffect(() => {
        if (moduleName === 'estimate') {
            estimateRefetch?.();
        } else {
            refetch();
            resetGridState();
            applyGridState();
        }
    }, [displayCurr, saveEstimateGridLoading, isEstimateResetLoading]);

    useEffect(() => {
        if (moduleName === 'estiamte') {
            estimateRefetch?.();
        }
    }, [isFetchingEstimate, saveEstimateGridLoading, isEstimateResetLoading]);

    useEffect(() => {
        if (estimateGridLayoutState && moduleName === 'estimate') {
            setGridLayoutData?.(estimateGridLayoutState);
        }
    }, [isFetchingEstimate, estimateGridLayoutState, moduleName]);

    useEffect(() => {
        if (newGridLayoutState) {
            resetGridState();
            applyGridState();
        }
    }, [isResetLoading, isFetching, newGridLayoutState, isFetchingEstimate, gridView, isEstimateResetLoading]);

    const onGridReady = (params: GridReadyEvent) => {
        applyGridState();
        // If there is gridAssociation, then by default first Row will be opened.
        if (isAssociation) {
            params.api.addEventListener('firstDataRendered', () => {
                const firstRow = params.api.getRowNode('0');
                if (firstRow) {
                    firstRow.setExpanded(true);
                }
            });
        }
    };

    // Custom checkbox Header is registered to ag grid through frameWorks/Components
    const frameWorks: any = {
        checkboxHeader: CheckboxHeader,
    };
    useEffect(() => {
        if (saveEstimateGridLoading) {
            estimateRefetch?.();
        }
    }, [saveEstimateGridLoading, isEstimateResetLoading]);

    const isGridLoading: boolean = saveEstimateGridLoading ?? false;
    const isEstimateLoading : boolean = isEstimateResetLoading ?? false;
    const isEditGridLayoutLoading : boolean = isEditGridLayouttLoading ?? false;

    return (
        <Box className={classes.tabelFoot}>
            <Main>
                <Item>
                    <Box sx={{ height: '100%', width: '100%' }} className={isLoading ? classes.opacityBox : classes.noOpacity}>
                        <div
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            className={`ag-theme-alpine ${isCommodities ? classes.tableStylesCommodities
                                : classes.tableStyles}
                            ${classes.containerBox}`}
                            style={{
                                height: '400px',
                                width: '100%',
                            }}
                        >
                            {(isLoading || saveGridLoading || isResetLoading || saveEstimateGridLoading || isEstimateLoading || isGridLoading
                             || isResetEstimateLoading || isEditGridLayoutLoading)
                                && (
                                    <Loader
                                        loading={isLoading
                                    || saveGridLoading || isResetLoading
                                     || isGridLoading || isEstimateLoading || isResetEstimateLoading || isEditGridLayoutLoading}
                                        isFromAGGrid
                                    />
                                )}
                            <AgGridReact
                                // enableRowGroup
                                // suppressServerSideInfiniteScroll
                                blockLoadDebounceMillis={500}
                                className={classes.loaderBox}
                                ref={activeGridRef}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefWithDefault}
                                rowData={tableData}
                                getRowStyle={getRowStyle}
                                gridOptions={gridOptions}
                                onSelectionChanged={onSelectionChanged}
                                masterDetail={masterDetail}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                pinnedBottomRowData={footerData}
                                groupIncludeTotalFooter={groupIncludeTotalFooter}
                                detailCellRendererParams={detailCellRendererParams}
                                groupDisplayType={groupDisplayTypeVal}
                                suppressColumnMoveAnimation
                                suppressDragLeaveHidesColumns
                                groupDefaultExpanded={groupDefaultExpanded}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                groupRowRenderer={customGroupRowRenderer}
                                onGridReady={onGridReady}
                                quickFilterText={quickFilterText}
                                onFirstDataRendered={applyGridState}
                                onRowDataUpdated={applyAutosize}
                                groupHideOpenParents={groupHideOpenParents}
                                rowSelection={rowSelection}
                                rowModelType={datasource ? 'serverSide' : 'clientSide'}
                                serverSideDatasource={datasource}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                components={frameWorks}
                                isServerSideGroupOpenByDefault={groupsOpenByDefault || undefined}
                                detailRowAutoHeight
                                autoGroupColumnDef={autoGroupColumnDefs || undefined}
                                suppressRowHoverHighlight={autoGroupColumnDefs ? true : undefined}
                            />
                        </div>
                    </Box>
                </Item>
            </Main>
        </Box>
    );
}

export default AgGridComponent;
